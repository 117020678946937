import React, { useEffect, useRef, useState } from 'react'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import CloudIndexNav from '../../components/cloudIndexNav/cloudIndexNav'
// import CloudIndexResources from '../../components/cloudIndexResources/cloudIndexResources'
import Button from '../../components/button/button'
// import PdfViewer from '../../components/pdfViewer'

import InfraHeader from '../../img/the-infra-red-report.png'

import * as global from '../../components/globals.module.css'
import * as styles from './infra.module.css'
import { graphql } from 'gatsby'

export const query = graphql`
    query InfraredReports {
        page: sanityPage(title: { eq: "Infrared" }) {
            title
            _rawBody
            metaTitle
            metaDescription
            metaKeywords
            metaImage {
                asset {
                    _id
                    url
                }
            }
            infrared {
              documents {
                  year
                  googleDriveLink
              }
            }
        }
    }
`

const CloudReportPage = props => {
  const { data, errors } = props

  const filterRef = useRef()
  const [selectedReport, setSelectedReport] = useState(new Date().getFullYear().toString())
  const [selectedReportUrl, setSelectedReportUrl] = useState()

  const reports = data.page?.infrared?.documents.map(report => ({
    year: report.year,
    url: report.googleDriveLink
  })) || []

  const filterSelect = (e) => {
    const selection = e.target.value
    setSelectedReport(selection)
  }

  function previewLink (viewLink) {
    if (!viewLink) return null
    if (viewLink.includes('/view')) {
      return viewLink.replace('/view', '/preview')
    } else if (viewLink.includes('/preview')) {
      return viewLink + '/preview'
    }
  }

  function downloadLink (viewLink) {
    if (!viewLink) return null
    const fileIdMatch = viewLink.match(/\/d\/([a-zA-Z0-9_-]+)\//)
    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1]
      return `https://drive.google.com/uc?export=download&id=${fileId}`
    } else {
      return viewLink
    }
  }

  function trackDownload (report) {
    if (!report) return
    if (typeof gtag !== 'undefined') {
      gtag('event', `download the report ${report}`, {
        component: 'button'
      })
    }
  }

  function getFileUrlByYear () {
    const report = reports.find(doc => doc.year === selectedReport)
    setSelectedReportUrl(report.url ? previewLink(report.url) : null)
  }

  useEffect(() => {
    if (filterRef.current) {
      const selection = filterRef.current.value
      setSelectedReport(selection)
    }
  }, [reports])

  useEffect(() => {
    getFileUrlByYear()
  }, [selectedReport])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout page='cloudReport' hideContactBar version='1'>
      <SEO title='The InfraRed Report' description={data?.page?.metaDescription} keywords={data?.page?.metaKeywords ? data?.page?.metaKeywords.split(',') : []} imageFB={data?.page?.metaImage.asset.url} />

      <CloudIndexNav current='report' />

      <div className={global.offwhite} style={{ paddingTop: '60px' }}>
        <Container>
          <h1 className={global.highlight}>
            <img className={styles.reportHeader} src={InfraHeader} alt='The InfraRed Report' />
          </h1>

          {reports.length > 0 && (
            <div className={styles.reportFilter}>
              <div className={styles.filter}>
                <div className={styles.label}>Filter</div>
                <div className={styles.dropdown}>
                  <select ref={filterRef} onChange={filterSelect}>
                    {reports.length > 0 && reports.map((report, index) => (
                      <option key={index} value={report.year}>{report.year} Report</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          {selectedReportUrl && (
            <iframe
              className={styles.pdf} src={selectedReportUrl}
              allow='autoplay'
            />
          )}

          {/* <PdfViewer file={infraredReport} /> */}

          {selectedReportUrl && (
            <div className={styles.mobilePdf}>
              <Button link={downloadLink(selectedReportUrl)} onClick={() => trackDownload(selectedReport)}>Download the report</Button>
            </div>
          )}

        </Container>
      </div>

      {/* <CloudIndexResources withWave={true} /> */}

    </Layout>
  )
}

export default CloudReportPage
